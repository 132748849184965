.secondary {
  overflow: hidden;
  border-bottom: 1px solid #f4f4f4;

  /* scrollbar-width: none;
    -ms-overflow-style: none;
    &::-webkit-scrollbar {
      width: 0;
      height: 0;
    } */
}
.navContainer {
  scrollbar-width: none; /* Firefox */

  -ms-overflow-style: none; /* Internet Explorer 10+ */
  &::-webkit-scrollbar {
    /* WebKit */
    width: 0;
    height: 0;
  }

  display: flex;
  /* overflow-x: scroll; */
  overflow: auto;
  max-width: 100%;
  background: #fcfcfc;
  white-space: nowrap;
  margin: 0;
  align-items: center;
  color: #111;
  width: 100%;
  position: relative;
  position: fixed;
  z-index: 1;
  & .scrollableLink:last-of-type {
    padding-right: 2rem;
  }
}

.scrollableLink {
  /* overflow-x: scroll; */
  position: relative;
  margin-right: 0.5rem;
  font-size: 0.9rem;
  padding: 0.2rem 1rem;
  /* width: 100%; */
  transition: all 200ms ease;
  cursor: pointer;
  user-select: none;
  text-transform: capitalize;
  /* border-right: 1px solid #f4f4f4; */
  margin: 0.25rem 0;

  &:hover {
    background-color: #f4f4f4;
    & .scrollableLinkChildren {
      opacity: 1;
    }
    & .scrollableLinkChildren {
      display: block;
    }
  }
}

.navContainer > li:first-of-type {
  margin-left: 3rem;
}

.navContainer > li:last-of-type {
  margin-right: 2rem;
}

.scrollableLinkChildren {
  /* padding: 1rem; */
  display: none;
  margin-top: 0.65rem;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.2);
  background-color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 33;
  & li {
    &:hover {
      background: #f1f1f1;
      & > a {
        color: inherit;
      }
    }
    & > a {
      padding: 0.5rem 2rem 0;

      display: inline-block;
      width: 100%;
      height: 100%;
      color: #111;

      &:hover {
        text-decoration: none;
      }
    }
    &:last-child > a {
      padding: 0.5rem 2rem;
    }
  }
  /* overflow: hidden; */
}

.childLabel {
  font-size: 0.9rem;
}

.icon {
  font-size: 2rem;
  color: var(--branding);
}

.sliderButton {
  position: fixed;
  z-index: 33;
  padding: 0.5em;
  border: none;
  background-color: #f5f5f5;
  /* border: 1px solid #aaa; */
  height: 60px;
  cursor: pointer;
  transition: all 200ms ease;
  top: 72px;
  &:hover {
    background: #f1f1f1;
  }
  /* &:disabled {
      pointer-events: none;
    } */
}

.sliderButton.left {
  left: 0;
  /* margin-right: 1rem; */
}

.sliderButton.right {
  right: 0;
}

.sliderButton {
  &:disabled {
    & .icon {
      color: #777777;
      opacity: 0.8;
    }
  }
}

.disabled {
  cursor: auto;
  & .icon {
    color: #777777 !important;
    opacity: 0.8;
  }
}

.menuIcon {
  width: 24px;
  height: 24px;
  margin-right: 1rem;
}
.link {
  color: #111;
}
.platFormLink {
  color: #111;
  padding: 0rem 0.25rem;
  &:hover {
    text-decoration: none;
    background-color: #f5f5f5;
  }
}
.menu {
  z-index: 999;
}
.profileMenuContent {
  min-width: 276px;
  width: 276px;
  padding-top: 20px;
  z-index: 9999;
}
.selectLabel {
  font-weight: 500;
  font-size: 16px;
}
.platFormContainer {
  display: flex;
  justify-content: space-around;
  padding: 1rem;
}
.left {
  display: flex;
  flex-direction: column;
  padding-right: 2rem;
}
.right {
  display: flex;
  flex-direction: column;
}
.staticLink {
  margin: 0;
  color: gray;
  padding: 0.25rem;
}
