@import '../../../styles/propertySets.css';
.headings {
  text-align: center;
  margin-bottom: 4rem;
}
.container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 2rem;
}
.bodyContainer {
  height: 100px;
  width: 100px;
  margin: 0rem 4rem;
  margin-bottom: 7rem;
}
.image {
  width: 100px;
  height: 100px;
}
.text {
  text-align: center;
  line-height: 21px;
  color: #9d9a9a;
  margin-left: -25px;
}
.buttonContainer {
  display: flex;
  justify-content: center;
}
.heroButton {
  @apply --marketplaceButtonStyles;
  width: 100%;
  @media (--viewportMedium) {
    display: block;
    width: 260px;
  }
}
