@import '../../../styles/propertySets.css';
.container {
  display: block;
  @media (--viewportMedium) {
    display: flex;
    margin: 0rem 2rem;
  }
}
.left {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 1rem;
  width: 100%;
  @media (--viewportMedium) {
    height: 200px;
    width: 50%;
    padding: 0 3rem;
    margin-bottom: 0;
  }
}
.right {
  /* height: 200px; */

  width: 100%;
  @media (--viewportMedium) {
    /* width: 50%; */
    padding: 0 3rem;
  }
}
.plainText {
  margin: 0.25rem 0;
  font-size: 30px;
  font-weight: 600;
}
.highLightText {
  margin: 0.25rem 0;
  font-size: 30px;
  font-weight: 600;
  color: var(--marketplaceColor);
}
.trigger {
  display: flex;
  justify-content: space-between;
  border: 1px solid #e9e5e5;
  padding: 0.5rem 2rem;
  font-size: 16px;
  background-color: #e9e5e5;
  cursor: pointer;
}
.image {
  height: 80px;
  width: 80px;
}
.triggerText {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0;
  /* margin-top: 2.4rem; */
  font-size: 16px;
  color: gray;
  margin-left: 0.25rem;
}
.downIcon {
  height: 30px;
  width: 30px;
  margin-top: 1.5rem;
  margin-left: 0.5rem;
}
.paragraphText {
  margin: 0.5rem 0;
  line-height: 1.6;
  font-size: 14px;
  color: #747171;
}
.paragraphBold {
  margin: 0.5rem 0;
  line-height: 1.6;
}

.buttonContainer {
  display: flex;
  justify-content: center;
}
.heroButton {
  @apply --marketplaceButtonStyles;
  background-color: transparent;
  border: 2px solid var(--marketplaceColor);
  width: 100%;
  color: var(--marketplaceColor);
  @media (--viewportMedium) {
    display: block;
    width: 260px;
  }
  &:hover {
    background-color: transparent;
    color: var(--marketplaceColorDark);
  }
}
